import React from "react";
import ImageSlider from "../../../../components/ImageSlider";

function Software() {
  return (
    <div className="flex flex-col items-center mt-16">
      <div
        className="bg-center bg-cover h-28 md:h-28 lg:h-28 w-full flex items-start justify-center"
        style={{
          backgroundImage:
            "url('/Content/images/products/product_heading.jpg')",
        }}
      >
        <div className="max-w-7xl w-full pl-0">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white p-4 sm:p-6 md:p-10">
            Software Development Projects
          </h1>
        </div>
      </div>

      <h1 className="text-xl font-semibold p-4 mt-4 mb-6 bg-gray-100 rounded-lg shadow-md">
        The company has developed a series of software products to fit
        particular customer requirements. Some examples:
      </h1>

      {/* Sections */}
      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <ImageSliderSection
          slides={[
            {
              backgroundImage: "/Content/images/products/marl/espt_map.png",
              modalImage: "/Content/images/products/marl/espt_map.png",
            },
            {
              backgroundImage: "/Content/images/products/marl/espt_details.png",
              modalImage: "/Content/images/products/marl/espt_details.png",
            },
            {
              backgroundImage: "/Content/images/products/marl/espt_chart.png",
              modalImage: "/Content/images/products/marl/espt_chart.png",
            },
          ]}
        />
        <TextSection
          title="MARL Technologies Inc."
          paragraphs={[
            `This customer provides geotechnical coring services for their
          customers, who are geotechnical consultants, engineering
          construction companies, or property developers.`,
            `One of the tests which MARL’s equipment provides is the standard
          penetration test (eSPT), which records soil penetration after a
          series of measured blows, at various depths down the coring hole.
          The results determine the load bearing capacity of the soil, and
          this is a vital factor in foundation design for buildings and
          other engineered structures.`,
            `The MARL coring/testing hardware provides sensor readings which
          are transmitted to a third party database. The iTrack software
          product, called MARL eSPT, copies this data to a local database,
          and makes it accessible to MARL and its customers via a web
          service. After signing in, the viewer sees a map presentation of
          their core holes and can select any one for detailed information,
          which may only be seconds old.`,
          ]}
        />
      </section>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <TextSection
          title="Compact Compression Inc."
          paragraphs={[
            `This company manufactures compressors which are used to enhance
          oil recovery. These compressors are supplied with hardware
          support. Information monitored, collected and used by Compact to
          maintain the fleet. The compressors are equipped with sensors
          which measure intake and discharge pressures, oil levels and
          temperatures, and other factors. This data is sent to a third
          party database, on scheduled intervals or as exceptions occur.`,
            `The iTrack software product is called SkyView. As with MARL eSPT,
          it replicates the data to a local database and grants access to
          Compact Compression and its customers through a web service. After
          a signing in, the viewer sees a map presentation of their
          wells/compressors, and can select any one for detailed
          information.`,
            `SkyView has been in 24/7 service for five years, with
          improvements/upgrades during this time. It currently supports more
          than 50 user operators, running more than 1100 compressors in six
          countries around the world.`,
          ]}
        />
        <ImageSliderSection
          slides={[
            {
              backgroundImage:
                "/Content/images/products/skyview/skyview_map.png",
              modalImage: "/Content/images/products/skyview/skyview_map.png",
            },
            {
              backgroundImage:
                "/Content/images/products/skyview/compressor_details.png",
              modalImage:
                "/Content/images/products/skyview/compressor_details.png",
            },
            {
              backgroundImage:
                "/Content/images/products/skyview/pressure_chart.png",
              modalImage: "/Content/images/products/skyview/pressure_chart.png",
            },
            {
              backgroundImage:
                "/Content/images/products/skyview/status_history.png",
              modalImage: "/Content/images/products/skyview/status_history.png",
            },
          ]}
        />
      </section>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <ImageSliderSection
          slides={[
            {
              backgroundImage: "/Content/images/products/amulet/event_page.png",
              modalImage: "/Content/images/products/amulet/event_page.png",
            },
            {
              backgroundImage: "/Content/images/products/amulet/event.png",
              modalImage: "/Content/images/products/amulet/event.png",
            },
            {
              backgroundImage: "/Content/images/products/amulet/chat.png",
              modalImage: "/Content/images/products/amulet/chat.png",
            },
            {
              backgroundImage: "/Content/images/products/amulet/map.png",
              modalImage: "/Content/images/products/amulet/map.png",
            },
            {
              backgroundImage: "/Content/images/products/amulet/resource.png",
              modalImage: "/Content/images/products/amulet/resource.png",
            },
          ]}
        />

<TextSection
  title="Amulet Response"
  paragraphs={[
    <>
      Amulet Response is an app written for our customer{" "}
      <a
        href="https://sandhurstconsulting.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        Sandhurst Consulting Inc
      </a>
      . Sandhurst provides emergency response plans and associated training for
      clients including oil and gas companies, mining firms, pipeline operators,
      major municipalities, and institutions. These clients are mandated to maintain
      up-to-date and properly distributed emergency response plans (ERPs).
    </>,

    `In an emergency, the priorities are assembling the appropriate response team and swiftly 
    executing the ERP.`,

    `Amulet is an app written for both Android and iOS smartphones in Flutter, a common 
    development language. A web version is also provided. The main functional features are:`
  ]}
  list={[
    <>
      <strong>Events:</strong> Allows users to create an initial emergency event, notifying other 
      users who can confirm their participation in the response. Notifications include event 
      descriptions, map locations of the event, and responder locations.
    </>,
    <>
      <strong>Chat:</strong> Enables initial responders to form a team, chat, share documents and 
      photos, and potentially transition to other platforms through included links. Also, it is 
      easy to add or edit responders.
    </>,
    <>
      <strong>Map:</strong> Displays the locations of all responders and the event itself.
    </>,
    <>
      <strong>Resources:</strong> Houses the actual ERP.
    </>
  ]}
  additionalSections={[
    {
      paragraphs: ["There are admin. features, including the ability to:"],
      list: [
        <strong>Add or edit responders.</strong>,
        <strong>Manage user groups, companies, divisions, and branches.</strong>,
        <strong>Assign or modify user privileges.</strong>,
      ],
    },
    {
      paragraphs: ["The design requirements for Amulet were:"],
      list: [
        <>
          <strong>Fully mobile:</strong> Responders can be anywhere.
        </>,
        <>
          <strong>Independent of company/agency networks:</strong> An emergency can include a 
          cyberattack or compromised network.
        </>,
        <>
          <strong>Secure cloud-based data:</strong> Hosted on Google Firebase.
        </>,
        <>
          <strong>Fast to set up and configure:</strong> Ensuring ease of deployment.
        </>,
      ],
    },
  ]}
/>






      </section>
    </div>
  );
}

function ImageSliderSection({ slides }) {
  return (
    <div className="w-full md:flex-1 pr-5 min-h-[300px]">
      {" "}
      {/* Changed flex-1 to w-full and md:flex-1 */}
      <ImageSlider slides={slides} />
    </div>
  );
}
function TextSection({ title, paragraphs, list, additionalSections }) {
  return (
    <div className="flex-1 pl-5 bg-white p-8 shadow-md rounded-lg max-w-3xl">
      <h2 className="text-3xl font-bold mb-6">{title}</h2>

      {paragraphs &&
        paragraphs.map((p, index) => (
          <p className="mb-4 mt-2" key={index}>
            {p}
          </p>
        ))}

      {list && (
        <ul className="list-disc list-inside mb-6">
          {list.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      )}

      {additionalSections &&
        additionalSections.map((section, index) => (
          <div key={index} className="mt-8">
            <h3 className="text-2xl font-semibold mb-4">{section.title}</h3>
            {section.paragraphs &&
              section.paragraphs.map((p, pIndex) => (
                <p className="mb-4 mt-2" key={pIndex}>
                  {p}
                </p>
              ))}
            {section.list && (
              <ul className="list-disc list-inside">
                {section.list.map((item, itemIndex) => (
                  <li key={itemIndex} className="mb-2">
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
    </div>
  );
}



export default Software;
